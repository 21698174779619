import { useEffect } from 'react'
import { useLocation } from '@reach/router'

declare global {
  interface Window {
    InsiderQueue: Array<{ type: string } & Record<string, any>>
  }
}

const InsiderEventHome = () => {
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/') {
      window.InsiderQueue = window.InsiderQueue || []
      window.InsiderQueue.push({ type: 'home' })
      window.InsiderQueue.push({ type: 'init' })
    }
  }, [location.pathname])

  return null
}

export default InsiderEventHome
